<template>
  <div id="container"></div>
  <div class="top" id="top">
    <span class="span" v-for="(item, index) in jl" :key="index" :style="{transform:`translateX(${jl[index]._t}px)`}">{{item._title}}</span>
  </div>
  <div class="map-tip">
    <div>
      <div class="dis">
        <img src="../../assets/video.png" />
        <span>摄像头</span>
      </div>
      <div class="dis">
        <img src="../../assets/mapIcon/IP.png" />
        <span>IP</span>
      </div>
      <div class="dis">
        <img src="../../assets/mapIcon/4G.png" />
        <span>4G</span>
      </div>
      <div class="dis">
        <img src="../../assets/mapIcon/dtmb.png" />
        <span>DTMB</span>
      </div>
      <div class="dis">
        <img src="../../assets/mapIcon/DVB-C.png" />
        <span>DVB-C</span>
      </div>
      <div class="dis">
        <img src="../../assets/mapIcon/FM.png" />
        <span>FM</span>
      </div>
<!--      <div class="dis">-->
<!--        <img src="../../assets/mapIcon/IP.png" />-->
<!--        <span>应急广播</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../../assets/mapIcon/4G.png" />-->
<!--        <span>地震传</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../../assets/mapIcon/dtmb.png" />-->
<!--        <span>森林防火</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../../assets/mapIcon/DVB-C.png" />-->
<!--        <span>防汛</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../../assets/mapIcon/FM.png" />-->
<!--        <span>地质灾害</span>-->
<!--      </div>-->

      <div class="bom">

        <div>
          <img src="../../assets/mapIcon/a.png" />
          <span>适配器</span>
        </div>

        <div>
          <img src="../../assets/mapIcon/b.png" />
          <span>智能音柱</span>
        </div>

        <div>
          <img src="../../assets/mapIcon/c.png" />
          <span>终端</span>
        </div>

        <div>
          <img src="../../assets/mapIcon/d.png" />
          <span>IP话筒</span>
        </div>
      </div>
    </div>

    <div>
      <div class="title">设备颜色状态</div>
      <div class="fff">
        <div>
          <i></i>
          <span style="color: #5FF12B">空闲</span>
        </div>

        <div>
          <i></i>
          <span style="color: #4DBAF2">工作</span>
        </div>

        <div>
          <i></i>
          <span style="color: #F59F25">故障</span>
        </div>

        <div>
          <i></i>
          <span style="color: #F73F3F">离线</span>
        </div>

      </div>
    </div>


    <el-dialog title="摄像头" v-model="dialogVisible" width="30%" :before-close="handleClose">
      <div style="margin-bottom: 30px">
        <el-button plain  @click="isViode = true">实时监控</el-button>
        <el-button plain  @click="lslx">历史录像</el-button>
      </div>

      <div class="dialog">
        <div class="dialogtop">
          <div><span>名称：</span><span>{{infoWindow.cname || '暂无'}}</span></div>
          <div><span>型号：</span><span>{{infoWindow.cmodel || '暂无'}}</span></div>
          <div><span>类型：</span><span>{{infoWindow.title || '暂无'}}</span></div>
          <div><span>坐标：</span><span>{{infoWindow.location || '暂无'}}</span></div>
          <div><span>安装地址：</span><span>{{infoWindow.installAddress || '暂无'}}</span></div>
        </div>
        <div class="dialogbom">
          <iframe v-if="isViode" :src="infoWindow.serial" style="pointer-events: none" class="iframe"></iframe>

          <video v-else id="videoElement" style="width: 100%" controls preload="auto" type="rtmp/flv"></video>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHomeMapCount, getHomeMapPoint, getCameraList } from "../../api/screen/bigmap";
import { onMounted, reactive, ref, toRefs, computed, nextTick, onBeforeUnmount } from "vue";
import flvjs from "flv.js";
import { getuserInfo } from "@/api/userinfo";
export default {
  setup(){
    let dialogVisible = ref(false);
    let isViode = ref(true);
    let state = reactive({
      _fDom:null,
      contextMenu:null,
      myVideo:null,
      viaMarker:[],
      cluster:[],
      map:null,
      newsList: [],
      infoWindow:{},
      flvPlayer:null,
      socket:null,
      jl:[],
    })
    const optionLeft  = computed(() => {
          return {
            direction: 2,
            openWatch: false,
            limitMoveNum: 1,
            singleHeight: 0,
            singleWidth: 0,
            waitTime: 1000,
          }
        }
    );

    onBeforeUnmount(() => {
      state.socket.close();
      state.socket = null;
    });

    onMounted(async ()=>{
      state._fDom =  document.getElementById('top');
      function initWebSocket() {
        getuserInfo().then(res=>{
          const wsuri = `${window.g.socketUrl}/websocket/${res.data.data.userNo}`;
          // const wsuri = "ws://192.168.3.37:9889/websocket/administrator";
          state.socket = new WebSocket(wsuri);
          state.socket.onopen = ()=>{
            console.log('连接成功')
          };
          state.socket.onmessage = (e)=>{
            if(e.data == 'WebSocket连接成功') return;
            let form = JSON.parse(e.data);
            let _fWidth = state._fDom.getBoundingClientRect().width;
            state.jl.push({
              _title: `(收到信息):${form.sourceName} 类型为${form.msgType} 包号为${form.ebdId}`,
              _t: `${state.jl.length == 0 ? _fWidth : _fWidth * (state.jl.length + 1) }`,
            })
          }
        })
      }
      initWebSocket();

      setInterval(()=>{
        if(state.jl.length < 1) return;
        state.jl.forEach((item)=>{
          item._t -= 5;
        })
      },16)


      await getHomeMapCount().then(res=>{
        let data = res.data.data.listMap;
        state.map = new AMap.Map("container", {
          zoom: 10,
          resizeEnable: true,
          mapStyle: "amap://styles/924a0b80c02969b8a905eab4dd9f5431",
          center: [data[0].longitude, [data[0].latitude]],
          viewMode: '3D',
          pitch: 70, // 地图倾斜
        });
        data.forEach(item=>{
          let arr = eval(item.code);
          arr.map(val => {
            let code = eval(val);
            let polygon = new AMap.Polygon({
              map: state.map,
              path: code,
              strokeColor: "#00FFC6", //线颜色
              strokeOpacity: 1, //线透明度
              strokeWeight: 2,    //线宽
              fillColor: "#0D6854", //填充色
              fillOpacity: 0.35//填充透明度
            });
            state.map.add(polygon);
          })
        })


        res.data.data.list.forEach((item,index)=>{
          state.viaMarker[index] = {
            lnglat: [item.longitude,item.latitude],
            data: item
          }
        })
      })

      await getCameraList().then(res=>{
        res.data.data.forEach((item,index)=>{
          if(item.location){
            let location = item.location.split(',')
            state.viaMarker[index] = {
              lnglat: [location[0],location[1]],
              data: item
            };
          }
        })
      })

      function _renderMarker(context){
        let pub = new Map([
          ['townAdapter','a'],
          ['intelligent', 'b'],
          ['expander','c'],
          ['soundPost','c'],
          ['ipMIC','d'],
          ['countyAdapter','a'],
        ])
        let type = new Map([
          ['1', 'fm'],
          ['2', 'dvb-c'],
          ['3', 'dtmb'],
          ['4', 'ip'],
          ['5', '4g']
        ])
        let imgType = new Map([
          ['a_4g_2', 'gif'],
          ['a_dvb-c_2', 'gif'],
          ['a_ip_2', 'gif'],
          ['b_dtmb_2', 'gif'],
          ['b_fm_2', 'gif'],
          ['c_4g_2', 'gif'],
          ['c_dvb-c_2', 'gif'],
          ['c_ip_2', 'gif'],
          ['d_dtmb_2', 'gif'],
          ['d_fm_2', 'gif'],
          ['a_dtmb_2', 'gif'],
          ['a_fm_2', 'gif'],
          ['b_4g_2', 'gif'],
          ['b_dvb-c_2', 'gif'],
          ['b_ip_2', 'gif'],
          ['c_dtmb_2', 'gif'],
          ['c_fm_2', 'gif'],
          ['d_4g_2', 'gif'],
          ['d_dvb-c_2', 'gif'],
          ['d_ip_2', 'gif'],
          ['default', 'png'],
        ])
        let content = null;
        if(context.data[0].data.cname){
          content = `<image src="${require(`../../assets/video.png`)}"></image>`
        }else {
          let ob = `${pub.get(context.data[0].data.type)}_${type.get(context.data[0].data.communicationMode)}_${context.data[0].data.workStatus}`;
          content = `<image src="${require(`../../assets/homeIcon/${ob}.${imgType.get(ob) || imgType.get('default')}`)}"></image>`
        }
        context.marker.setContent(content)
      }

      state.cluster = new AMap.MarkerCluster(state.map, state.viaMarker, {
        gridSize: 220,
        renderMarker: _renderMarker
      });

      // 增加右键弹框
      if(!state.contextMenu) state.contextMenu = new AMap.ContextMenu();
      state.contextMenu.addItem("关闭聚合", ()=> {
        state.cluster.setMaxZoom(1);
        state.contextMenu.close();
      }, 0);
      state.contextMenu.addItem("开启聚合", ()=> {
        state.cluster.setMaxZoom(18);
        state.contextMenu.close();
      }, 1);
      state.map.on('rightclick', function (e) {
        state.contextMenu.open(state.map, [e.lnglat.lng, e.lnglat.lat]);
      });

      state.cluster.setMaxZoom(1);



      function clearInfoWindow(){
        state.map.clearInfoWindow();
      }

      function createInfoWindow(mk){
        let info = document.createElement('div');
        info.style =  `width: 392px;
                           background: #0E7C5D;
                           opacity:.7;
                           border: 1px solid #0E7C5D;
                           position: relative;
                           border-radius: 5px;`;
        let closeX = document.createElement('div');
        closeX.onclick = clearInfoWindow;
        closeX.innerHTML = 'X';
        closeX.style = `  position: absolute;
                             right: 20px;
                             top: 10px;
                             color: #00D3A4;
                             font-size: 28px`;
        info.innerHTML = `
                      <div style="display: flex;flex-direction: column; padding:20px; color: #00FFC6; font-size: 18px;">
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">设备名称：</span>
                          <span>${mk.name}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">设备类型名称：</span>
                          <span>${mk.typeName}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">资源编码：</span>
                          <span>${mk.code}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">安装位置：</span>
                          <span>${mk.installAddr || '暂无位置'}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">经纬度：</span>
                          <span>${mk.latitude},${mk.longitude}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">时间：</span>
                          <span>${mk.createTime}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">是否具有回传：</span>
                          <span>${mk.rebackFlag == 1 ? '是' : '否'}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">所属区域：</span>
                          <span>${mk.regionName}</span>
                        </div>
                    </div>`;
        info.appendChild(closeX);
        return info;
      }

      state.cluster.on('click',(item)=>{
        let _cluster = item.cluster.p.data;
        if(_cluster.cname){
          dialogVisible.value = true;
          state.infoWindow = _cluster;
        }else {
          var infoWindow = new AMap.InfoWindow({
            isCustom: true,  //使用自定义窗体
            content: createInfoWindow(_cluster),
            offset: new AMap.Pixel(9, -9)
          });
          infoWindow.open(state.map, [_cluster.longitude,_cluster.latitude]);
        }
      })

      // state.map.on('dragend', ()=>{
      //   // state.cluster.clearMarkers();
      //   state.cluster = [];
      //   state.map.setStatus({
      //     dragEnable:false
      //   })
      //   setTimeout(()=>{
      //     state.cluster = new AMap.MarkerCluster(state.map, state.viaMarker, { gridSize: 220, renderMarker: _renderMarker });
      //     state.map.setStatus({
      //       dragEnable: true
      //     })
      //   },2000)
      // });

      getHomeMapPoint().then(res=>{
        let data = res.data.data;
        let startPoint = [data.start.longitude,data.start.latitude];
        let features = data.endList.map(item=>{
          return{
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [item.longitude,item.latitude]
            }
          }
        })

        let line = data.endList.map(item=>{
          return{
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                startPoint,
                [item.longitude,item.latitude]
              ]
            }
          }
        })

        var loca = new Loca.Container({
          map:state.map,
        });

        var scatter = new Loca.ScatterLayer({
          loca,
          zIndex: 10,
          opacity: 1,
          visible: true,
          zooms: [2, 22],
        });

        var pointGeo = new Loca.GeoJSONSource({
          data: {
            "type": "FeatureCollection",
            features
          }
        });
        scatter.setSource(pointGeo);
        scatter.setStyle({
          unit: 'meter',
          size: [10000, 10000],
          borderWidth: 0,
          texture: 'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
          duration: 2000,
          animate: true,
        });
        loca.add(scatter);

        var geo = new Loca.GeoJSONSource({
          data: {
            "type": "FeatureCollection",
            features:line,
            openWatch: true,
            limitMoveNum: state.newsList.length,
          }
        });

        // 弧线
        var pulseLink = new Loca.PulseLinkLayer({
          zIndex: 20,
          opacity: 1,
          visible: true,
          zooms: [2, 22],
        });
        pulseLink.setSource(geo);
        pulseLink.setStyle({
          unit: 'meter',
          dash: [40000, 0, 40000, 0],
          lineWidth: function (_, feature) {
            console.log(_,feature)
            return [600, 600];
          },
          height: function (index, feat) {
            return feat.distance / 2;
          },
          // altitude: 1000,
          smoothSteps: 1000,
          speed: function (index, prop) {
            return 1000 + Math.random() * 150000;
          },
          flowLength: 100000,
          lineColors: function (index, feat) {
            return ['rgba(255,228,105,.6)', 'rgba(255,228,105,.6)', 'rgba(255,228,105,.6)'];
          },
          maxHeightScale: 0.3, // 弧顶位置比例
          headColor: 'rgba(255, 255, 204, 1)',
          trailColor: 'rgba(255, 255, 255, 1.0)'
        });
        loca.add(pulseLink);
        loca.animate.start();
      })
    })



    function lslx(){
      isViode.value = false;
      nextTick(()=>{
        if(flvjs.isSupported()){
          let videoElement = document.getElementById('videoElement');
          state.flvPlayer = flvjs.createPlayer({type: 'flv', cors: true, url: state.infoWindow.history});
          state.flvPlayer.attachMediaElement(videoElement);
          state.flvPlayer.load(); //加载
          state.flvPlayer.play();//播放
        }
      })
    }

    function handleClose(done){
      done()
      isViode.value = true;
      state.flvPlayer.pause();
    }
    return{
      handleClose,
      lslx,
      optionLeft,
      dialogVisible,
      isViode,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes run {
  0% { transform:translateX(1920px)}
  100%{transform:translateX(-662px)}
}
::v-deep .span {
  //animation:linear alternate forwards;
  //animation-name: run;
  //animation-duration: 20s;
  color: #FFFFFF;
  font-size: 30px;
  white-space: nowrap;
  display: inline-block;
}
.top{
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(#071D1B, .5);
  text-align: left;
  //line-height: 50px;
}
.dialog{
  width: 100%;
  .dialogtop{
      font-size: 24px;
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      >div{
        display: flex;
        align-items: center;
        span{
          &:nth-child(1){
            flex-basis: 30%;
            text-align: right;
          }
          &:nth-child(2){
            text-align: left;
            flex-basis: 70%;
          }
        }
      }
  }
  .dialogbom{
    margin-top: 30px;
    width: 100%;
    height: auto;
    .iframe{
      width: 100%;
      height: 100%;
    }
  }
}
#container {
  width: 100%;
  height: 100%;
}
.map-tip{
  width: 220px;
  height: 523px;
  position: absolute !important;
  bottom: 20px;
  right: 0;
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 383px 130px;
  grid-row-gap: 10px;
  >div{
    border: 1px solid #0E7C5D;
    background: rgba(#071010,.5);
    .title{
      font-size: 20px;
      font-weight: bold;
      color: #00FFC6;
      margin: 11px 0 10px 16px;
      text-align: left;
    }
    .fff{
      display: flex;
      flex-wrap:wrap;
      >div{
        flex-basis: 50%;
        font-size: 18px;
        font-weight: 400;
        color: #5FF12B;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        i{
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          margin-right: 15px;
          margin-left: 17px;
        }
      }
      >div:nth-child(1) i{
        background: #5FF12B;
        box-shadow: 0px 0px 0px 5px #266124;
      }
      >div:nth-child(2) i{
        background: #4DBAF2;
        box-shadow: 0px 0px 0px 5px #204657;
      }
      >div:nth-child(3) i{
        background: #F59F25;
        box-shadow: 0px 0px 0px 5px #523e19;
      }
      >div:nth-child(4) i{
        background: #F73F3F;
        box-shadow: 0px 0px 0px 5px #532121;
      }
    }
    .dis{
      display: flex;
      align-items: center;
      height: 53px;
      img{
        margin-left: 36px;
        width: 38px;
        height: 38px;
      }
      span{
        font-size: 26px;
        font-weight: 400;
        color: #00FFC6;
        margin-left: 34px;
      }
    }
    .bom{
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      font-size: 20px;
      font-weight: 400;
      color: #00FFC6;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 50%;
        span{
          width: 80px;
        }
      }
    }
  }
}
</style>